import React, { Component } from 'react'
import Slider from "react-slick";
import TMSPortal from '../../Images/portalekran.png'
import TMSPortal2 from '../../Images/TMSPortal2.png'
import TMSLoko from '../../Images/loko.png'
import TMSLoko2 from '../../Images/loko2.PNG'
import QR from '../../Images/qr.jpg'
import IMS from '../../Images/Ims.png'
import IMS2 from '../../Images/IMS2.jpg'
import OnBoard from '../../Images/onboard.PNG'
import Testbench from '../../Images/Testbench.jpeg'
const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
};

class Products extends Component {

    render() {
        return (
            <div id="products" >
                <h3 className="w-100 text-center mb-4">OUR PROJECTS</h3>
                <div className="product-body">
                    <div className="mr-3" data-toggle="modal" data-target="#openPortal">
                        <img src={TMSPortal} alt="first" />
                        <p>TMS PORTAL</p>
                        {/* 
                        <button type="button" className="btn btn-primary px-2 py-1" >
                                Detaylar
                        </button> */}

                    </div>
                    <div className="mr-3" data-toggle="modal" data-target="#openLoko">
                        <img src={TMSLoko} alt="first" />
                        <p>TMS LOKOMOTİF</p>


                    </div>
                    <div className="mr-3" data-toggle="modal" data-target="#openQR">
                        <img src={QR} alt="first" />
                        <p>TMSQR</p>

                    </div>

                </div>
                
                <div className="product-body">
                    <div className="mr-3" data-toggle="modal" data-target="#openIMS">
                        <img src={IMS} alt="first" />
                        <p>IMS</p>

                    </div>

                    <div className="mr-3" data-toggle="modal" data-target="#openTest">
                         <img src={Testbench} alt="testbenchbody" /> 
                        <p>TESTBENCH</p>

                    </div>

                    <div className="mr-3" data-toggle="modal" data-target="#openOnboard">
                        <img src={OnBoard} alt="first" />
                        <p>TMS ONBOARD</p>

                    </div>

                </div>

                <div className="modal fade" id="openLoko" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TMS LOKOMOTIF
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={TMSLoko} className="popup-img"
                                                alt={TMSLoko}></img>
                                        </div>
                                        <div>
                                            <img src={TMSLoko2} className="popup-img"
                                                alt={TMSLoko2}></img>
                                        </div>
                                    </Slider>
                                </div>
                                <p >

                                    <ul style={{ listStyleType: "disc" }}>
                                        <li>

                                            Innovative Maintenance Mentality
                                        </li>
                                        <li>
                                            Remote access
                                        </li>
                                        <li>
                                            Upcoming Maintenance Notifications
                                        </li>
                                        <li>
                                            Retrospective Maintenance Parameters Tracking
                                        </li>
                                        <li>
                                            Open to development
                                        </li>
                                    </ul>
                                    <b>Purpose;</b>
                                    <ol>
                                        <li>
                                            DE22000 Series Loko ERTMS monthly and 6-month maintenance parameters can be accessed and updated via the portal,
                                        </li>
                                        <li>
                                            Reminding of upcoming maintenance via notification
                                        </li>
                                    </ol>

                                    <b>Content;</b>
                                    <ul>
                                        <li>
                                            <b>Monthly Maintenance: </b>
                                            Monthly Maintenance Information of 22000 Locomotive Series can be accessed in detail.

                                        </li>
                                        <li>
                                            <b>
                                                Past Maintenance Informations: </b>
                                            Monthly and 6-monthly maintenance information of 22000 Locomotive Series can be accessed.

                                        </li>
                                        <li>
                                            <b>
                                                6 Monthly Maintenance: </b>The 6-Month Maintenance Information of the 22000 Locomotive Series can be accessed in detail.
                                        </li>
                                        <li>
                                            <b>
                                                Management panel: </b>Monthly and 6-Monthly information of 22000 Locomotive Series can be easily entered.
                                        </li>
                                        <li>
                                            <b>
                                                Upcoming Maintenance :</b>
                                            Monthly Maintenance Information of 22000 Locomotive Series
                                        </li>
                                    </ul>
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="openPortal" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TMS PORTAL
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={TMSPortal} className="popup-img"
                                                alt={TMSPortal}></img>
                                        </div>
                                        <div>
                                            <img src={TMSPortal2} className="popup-img"
                                                alt={TMSPortal2}></img>
                                        </div>
                                    </Slider>
                                </div>
                                <p >
                                    <b>Content;</b>
                                    <ul>
                                        <li>
                                            <b>News and Announcements: </b>Effective display of in-house news and announcements,
                                        </li>
                                        <li>
                                            <b>Train Cards: </b>
                                            On train cards; Km, Expedition, Cerbox, Profile Dimensions, Maintenance Dates, Maintenance Notes etc. Tracking all information about the train both on the Kiosk screen and on the internet,
                                        </li>
                                        <li>
                                            <b>Recording the Maintenances Performed: </b>Recording and retrospective follow-up of the work done with the on-board maintenance forms to be added to the train cards,
                                        </li>
                                        <li>
                                            <b>
                                                Maintenance-Repair Manuals: </b>Maintenance-repair manuals in the profiles and guides and forms that can be accessed from smart devices at any time,
                                        </li>
                                        <li>
                                            <b>
                                                Open for Development: </b>The developable open platform allows the integration of different projects.
                                        </li>
                                    </ul>
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="openQR" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TMSQR APPLICATION
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={QR} className="popup-img"
                                                alt={QR}></img>
                                        </div>
                                        <div>
                                            <img src={QR} className="popup-img"
                                                alt="a"></img>
                                        </div>
                                    </Slider>
                                </div>
                                <p >
                                    With the TmsQR application and QR codes;
                                    <ul style={{ listStyleType: "circle" }}>
                                        <li>

                                            Equipment calibration dates and remaining days information,
                                        </li>
                                        <li>
                                            Calibration certificates of equipment,

                                        </li>
                                        <li>

                                            Construction equipment operator lists,

                                        </li>
                                        <li>
                                            Chemical stock and expiry date tracking,

                                        </li>
                                        <li>

                                            Chemical substance MSDS (Material Safety Datasheet) information

                                        </li>
                                    </ul>
                                    And we can view many other information and update it remotely without changing the QR code.
                                    <p>
                                        <b>The conveniences brought by the TmsQR application;</b>
                                        <ul style={{ listStyleType: "square" }}>
                                            <li>

                                                Remote updating of all equipment information,
                                            </li>
                                            <li>

                                                Being able to easily access information about the equipment with a user-friendly interface,
                                            </li>
                                            <li>

                                                When the calibration dates change, the label replacement work on the device is eliminated, </li>
                                            <li>
                                                Stock and expiry date tracking of chemicals,
                                            </li>
                                        </ul>
                                    </p>
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="openIMS" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    IMS - Smart Train Tracking System
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={IMS} className="popup-img"
                                                alt={IMS}></img>
                                        </div>
                                        <div>
                                            <img src={IMS2} className="popup-img"
                                                alt={IMS2}></img>
                                        </div>
                                    </Slider>
                                </div>
                                <p>
                                    <ul style={{ listStyleType: "decimal" }}>
                                        <li>
                                            Acceleration in three axes
                                        </li>
                                        <li>
                                            Heat
                                        </li>
                                        <li>

                                            Instant Location Information
                                        </li>
                                    </ul>
                                    <b>Purpose;</b>
                                    <ul style={{ listStyleType: "square" }}>
                                        <li>
                                            To be able to instantly monitor high-speed train movements,
                                        </li>
                                        <li>

                                            Collecting road condition and shaking data for road and passenger comfort,
                                        </li>
                                    </ul>
                                    <p className="mb-2">

                                        A set of equipment is mounted on each trainset. These devices are; It consists of a set of sensor packages to receive data and a telecommunication device to send this data back to the server.</p>
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="openTest" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TESTBENCH
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                        <img src={Testbench} className="popup-img"
                                                alt={Testbench}></img>
                                        </div>

                                    </Slider>
                                </div>
                                <p >
                                    TMS TESTBENCH
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="openOnboard" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TMS ONBOARD
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={OnBoard} className="popup-img"
                                                alt={OnBoard}></img>
                                        </div>
                                    </Slider>
                                </div>
                                <p >
                                    on-the-air
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default Products;