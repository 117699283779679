import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Images/tmsrubber.png'
import En from '../Images/Languages/eng.png'
import Tr from '../Images/Languages/tr.png'
import Ger from '../Images/Languages/ger.png'

export default class Navbar extends Component {

    render() {
        return (
            <nav>
                <div id="logo">
                    <img src={Logo} alt={Logo} style={{ width: "150px", height: "60px" }} />
                </div>

                <label htmlFor="drop" className="toggle">SPEISEKARTE</label>
                <input type="checkbox" id="drop" />
                <ul className="menu">
                    <li><Link to="/de">STARTSEITE</Link></li>
                    <li >

                        <a href="#about-us">ÜBER UNS</a>
                    </li>
                    <li>
                        <a href="#products">UNSERE PROJEKTE</a>
                    </li>
                    <li><a href="#news">NACHRICHTEN</a></li>

                    <li><a href="#references">VERWEISE</a></li>
                    <li><a href="#contact">KONTAKT</a></li>
                    <li>
                        <label htmlFor="drop-3" className="toggle">SPRACHEN</label>
                        <a href="/" style={{pointerEvents: "none"}}>SPRACHEN</a>
                        <input type="checkbox" id="drop-3" />
                        <ul>
                            <li>
                                <Link to="/eng">Englisch<img src={En} alt={En} className="ml-2" /></Link>
                            </li>
                            <li>
                                <Link to="/">Türkisch<img src={Tr} alt={Tr} className="ml-2" /></Link>
                            </li>
                            <li>
                                <Link to="/de">Deutsch<img src={Ger} alt={Ger} className="ml-2 mb-1" /></Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        )
    }
}
