import HomePage from "./TR/HomePage";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import HomePageENG from './ENG/HomePage'
import HomePageDe from './GER/HomePage'
import NotFound from "./NotFound";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/eng" component={HomePageENG} />
        <Route exact path="/de" component={HomePageDe} />
        <Route exact component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
