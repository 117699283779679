import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Images/tmsrubber.png'
import En from '../Images/Languages/eng.png'
import Tr from '../Images/Languages/tr.png'
import Ger from '../Images/Languages/ger.png'
export default class Navbar extends Component {

    render() {
        return (
            <nav>
                <div id="logo">
                    <img src={Logo} alt={Logo} title={Logo} style={{ width: "150px", height: "60px" }} />
                </div>

                <label for="drop" className="toggle">MENU</label>
                <input type="checkbox" id="drop" />
                <ul className="menu">
                    <li><a href="#homepage" >ANA SAYFA</a></li>
                    <li >
                        <a href="#about-us">HAKKIMIZDA</a>
                    </li>
                    <li>
                        <a href="#products">PROJELERİMİZ</a>
                    </li>
                    <li><a href="#news">HABERLER</a></li>
                    <li><a href="#references">REFERANSLAR</a></li>
                    <li><a href="#contact">İLETİŞİM</a></li>
                    <li>
                        <label for="drop-3" className="toggle">DİLLER</label>
                        <a href="/" style={{pointerEvents: "none"}} alt="diller"> DİLLER</a>
                        <input type="checkbox" id="drop-3" />
                        <ul>
                            <li>
                                <Link to="/eng">İngilizce<img src={En} alt={En} className="ml-2"/></Link>
                            </li>
                            <li>
                                <Link to="/">Türkçe<img src={Tr} alt={Tr} className="ml-2"/></Link>
                            </li>
                            <li>
                                <Link to="/de">Almanca<img src={Ger} alt={Ger} className="ml-2 mb-1"/></Link>
                            </li>
                        </ul>
                    </li>
                </ul>


            </nav>

        )
    }
}
