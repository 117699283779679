import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Images/tmsrubber.png'
import En from '../Images/Languages/eng.png'
import Tr from '../Images/Languages/tr.png'
import Ger from '../Images/Languages/ger.png'

export default class Navbar extends Component {

    render() {
        return (
            <nav>
                <div id="logo">
                    <img src={Logo} alt={Logo} style={{ width: "150px", height: "60px" }} />
                </div>

                <label htmlFor="drop" className="toggle">MENU</label>
                <input type="checkbox" id="drop" />
                <ul className="menu">
                    <li><Link to="/eng">HOMEPAGE</Link></li>
                    <li >
                        <a href="#about-us">ABOUT US</a>
                    </li>
                    <li>
                        <a href="#products">OUR PROJECTS</a>
                    </li>
                    <li><a href="#news">NEWS</a></li>

                    <li><a href="#references">REFERENCES</a></li>
                    <li><a href="#contact">CONTACT</a></li>
                    <li>
                        <label htmlFor="drop-3" className="toggle">LANGUAGES</label>
                        <a href="/" style={{ pointerEvents: "none" }}>LANGUAGES</a>
                        <input type="checkbox" id="drop-3" />
                        <ul>
                            <li>
                                <Link to="/eng">English<img src={En} alt={En} className="ml-2" /></Link>
                            </li>
                            <li>
                                <Link to="/">Turkish<img src={Tr} alt={Tr} className="ml-2" /></Link>
                            </li>
                            <li>
                                <Link to="/de">German<img src={Ger} alt={Ger} className="ml-2 mb-1" /></Link>
                            </li>
                        </ul>
                    </li>
                </ul>


            </nav>

        )
    }
}
