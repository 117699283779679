import React, { Component } from 'react'

export default class AboutUs extends Component {
    render() {
        return (
            <div className="about-us" id="about-us">
                <h1>WER SIND WIR
                </h1>
                <p>
                    ABOUT US
                </p>
            </div>
        )
    }
}
