import React, { Component } from 'react'
import TCDD from '../../Images/Company/tcdd_logo.png'
import TCDDTa from '../../Images/Company/tcdd.png'
import Siemens from '../../Images/Company/siemens.png'
import Caf from '../../Images/Company/caf.png'
import DelphiSonic from '../../Images/Company/delphi.png'
import Izban from '../../Images/Company/izban.png'
class References extends Component {
    render() {
        return (
            <div id="references">
                <h3 className="text-center mb-4" >VERWEISE</h3>
                <div className="slider">

                    <div className="slide-track">
                        <div className="slide">
                            <img src={TCDD} height="150" width="200" alt={TCDD} />
                        </div>
                        <div className="slide">
                            <img src={TCDDTa} height="150" width="200" alt={TCDDTa} />
                        </div>
                        <div className="slide">
                            <img src={Siemens} height="150" width="200" alt={Siemens} />
                        </div>
                        <div className="slide">
                            <img src={Caf} height="150" width="200" alt={Caf} />
                        </div>
                        <div className="slide">
                            <img src={DelphiSonic} height="150" width="200" alt={DelphiSonic} />
                        </div>
                        <div className="slide">
                            <img src={Izban} height="150" width="200" alt={Izban} />
                        </div>


                        <div className="slide">
                            <img src={TCDD} height="150" width="200" alt={TCDD} />
                        </div>
                        <div className="slide">
                            <img src={TCDDTa} height="150" width="200" alt={TCDDTa} />
                        </div>
                        <div className="slide">
                            <img src={Siemens} height="150" width="200" alt={Siemens} />
                        </div>
                        <div className="slide">
                            <img src={Caf} height="150" width="200" alt={Caf} />
                        </div>
                        <div className="slide">
                            <img src={DelphiSonic} height="150" width="200" alt={DelphiSonic} />
                        </div>
                        <div className="slide">
                            <img src={Izban} height="150" width="200" alt={Izban} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default References;