import React, { Component } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper/core';
import TMSLoko from '../../Images/loko.png';
import TMSQR from '../../Images/qr.jpg';
import TMSPortal from '../../Images/portalekran.png';
import IMS from '../../Images/Ims.png';
import Testbench from '../../Images/Testbench.jpeg';

SwiperCore.use([Autoplay, Pagination, Navigation]);
export default class News extends Component {
    render() {
        return (
            <div className="news" id="news">
                <h3>NACHRICHTEN</h3>
                <Swiper spaceBetween={30} centeredSlides={true} loop={true} autoplay={{
                    "delay": 10000,
                    "disableOnInteraction": false
                }} pagination={{
                    "clickable": true
                }} navigation={true} className="mySwiper">

                    <SwiperSlide>
                        <img src={Testbench} alt="TmsTestBench" title="TmsTestBench" className="w-100" />
                        <p>
                            TESTBENCH PROJEMİZ YAKINDA YAYINDA !!!
                        </p>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src={TMSLoko} alt={TMSLoko} className="w-100" />
                        <p>
                            TMS LOKOMOTİF AÇILDI !!!
                        </p>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src={IMS} alt={IMS} className="w-100" />
                        <p>
                            IMS PROJEMİZ TAMAMLANDI !!!
                        </p>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src={TMSQR} alt={TMSQR} className="w-100" />
                        <p>
                            TMSQR YAYINDA !!!
                        </p>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src={TMSPortal} alt={TMSPortal} className="w-100" />
                        <p>
                            TMS PORTAL AÇILDI !!!
                        </p>
                    </SwiperSlide>

                </Swiper>
            </div>
        )
    }
}
