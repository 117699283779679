import React, { Component } from 'react'
import Contact from './Components/Contact'

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_visible: false
        };
    }

    componentDidMount() {
        var scrollComponent = this;
        document.addEventListener("scroll", function (e) {
            scrollComponent.toggleVisibility();
        });
    }

    toggleVisibility() {
        if (window.pageYOffset > 300) {
            this.setState({
                is_visible: true
            });
        } else {
            this.setState({
                is_visible: false
            });
        }
    }
    
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render() {
        const { is_visible } = this.state;

        return (
            <div className="footer" >

                <div className="footer-about text-left">
                    <h5 className="">LİNKLER</h5>
                    <ul className="pl-0">
                        <li>
                            <a href="#about-us">HAKKIMIZDA</a>
                        </li>
                        <li>
                            <a href="#products">PROJELERİMİZ</a>
                        </li>
                        <li>
                            <a href="#news">HABERLER</a>
                        </li>
                        <li>
                            <a href="#references">REFERANSLAR</a>
                        </li>
                    </ul>
                </div>
                <div className="footer-contact">
                    <h5>İLETİŞİM</h5>
                    <p>
                        Etimesgut YHT Araç Bakım Atölye Müdürlüğü Ayaş Yolu Bulvarı, Etiler Mah. No:89/C<br />
                        Ankara - TURKEY <br />
                        TELEFON: <a href="tel:05414264692"> 0541 426 46 92 </a> <br />
                        MAİL: <a href="mailto:ehuseyinbalin@tmstechnic.com">ehuseyinbalin@tmstechnic.com </a> <br />
                        <a href="mailto:atahayurdakul@tmstechnic.com">atahayurdakul@tmstechnic.com </a>
                    </p>
                </div>
                <div className="footer-social">
                    <h5>
                        BİZE MESAJ GÖNDERİN!
                    </h5>
                    <Contact />
                </div>


                <div className="down-footer" id="contact">
                    <div className="up-arrow">
                        <div className="scroll-to-top">
                            {is_visible && (
                                <div onClick={() => this.scrollToTop()}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg" className="svg"

                                    >
                                        <g transform="translate(-18.121 -3.364)">
                                            <rect
                                                ry="4.928"
                                                y="3.364"
                                                x="18.121"
                                                height="38.735"
                                                width="33.749"
                                                fill="transparent"
                                            />
                                            <g transform="translate(-.48 2.134)">
                                                <rect
                                                    ry="4.928"
                                                    y="1.229"
                                                    x="18.601"
                                                    height="38.735"
                                                    width="33.749"
                                                    fill="url(#b)"
                                                />
                                                <g fill="#ececec">
                                                    <path d="M22.435 17.62l4.684 4.685 5.044-5.044v19.352h6.625V17.26l5.044 5.044 4.683-4.684-13.04-13.04z" />
                                                    <path d="M22.435 17.62l4.684 4.685 5.044-5.044v19.352h6.625V17.26l5.044 5.044 4.683-4.684-13.04-13.04z" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            )}
                        </div>
                        <p className="m-0">TMS AR-GE Tarafından Yapılmıştır. Tüm Hakları Saklıdır. </p>
                    </div>
                </div>
            </div>
        )
    }
}
export default Footer