import React, { Component } from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import AboutUs from './Components/AboutUs'
import News from './Components/News'
import Products from './Components/Products'
import References from './Components/References'
import { Helmet } from "react-helmet";
import TMSArge from '../Images/arge.PNG'
class HomePage extends Component {

    componentDidMount = () => {
        document.documentElement.lang = "tr";
    }

    render() {
        return (
            <div>
                <Helmet>
                    <meta http-equiv="content-language" content="tr" />
                    <title data-react-helmet="true">
                        TMS AR-GE | TMS Tren Bakım Onarım A.Ş.
                    </title>
                    <meta
                        name="description"
                        content="TMS AR-GE | TMS ARGE Ekibi olarak projelerimizi görmek ve TMS ARGE ekibini tanımak için hemen sitemizi ziyaret edin | TMS Tren Bakım Onarım A.Ş."
                        data-react-helmet="true"
                    />
                    <meta name="keywords" content="TMS Ar-Ge" data-react-helmet="true" />

                    <link rel="canonical" href="https://www.tmsarge.com/" />
                    <link rel="alternate" hreflang="tr" href="https://www.tmsarge.com/" />
                    <link rel="alternate" hreflang="en" href="https://www.tmsarge.com/eng" />
                    <link rel="alternate" hreflang="de" href="https://www.tmsarge.com/de" />
                    <link rel="alternate" hreflang="x-default" href="https://www.tmsarge.com/" />

                </Helmet>
                <Navbar />

                <div className="carousel slide carousel-fade" data-ride="carousel" data-interval="5000" id="carousel-1">
                    <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active">
                            <img className="img-fluid w-100 d-block" src={TMSArge} alt="TMS Arge" title="TMS Arge" />
                            <div className="carousel-caption">
                                <h3 className="animated slideInDown">TMS AR-GE</h3>
                                <p className="animated slideInLeft">Yenilik, Modern, Yaratıcı ve Son Teknoloji</p>
                                <a href="#about-us" className="btn btn-outline-light btn-lg animated slideInUp mt-4" type="button">
                                    Daha Fazla
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="an">
                    <AboutUs />
                    <News />
                </div>

                <Products />

                <References />

                <Footer />
            </div>

        )
    }
}
export default HomePage;