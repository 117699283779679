import React, { Component } from 'react'
import Slider from "react-slick";
import TMSPortal from '../../Images/portalekran.png'
import TMSPortal2 from '../../Images/TMSPortal2.png'
import TMSLoko from '../../Images/loko.png'
import TMSLoko2 from '../../Images/loko2.PNG'
import QR from '../../Images/qr.jpg'
import IMS from '../../Images/Ims.png'
import IMS2 from '../../Images/IMS2.jpg'
import OnBoard from '../../Images/onboard.PNG'
import Testbench from '../../Images/Testbench.jpeg'
const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
};

class Products extends Component {

    render() {
        return (
            <div id="products" >
                <h3 className="w-100 text-center mb-4">UNSERE PROJEKTE</h3>
                <div className="product-body">
                    <div className="mr-3" data-toggle="modal" data-target="#openPortal">
                        <img src={TMSPortal} alt="first" />
                        <p>TMS PORTAL</p>
                        {/* 
                        <button type="button" className="btn btn-primary px-2 py-1" >
                                Detaylar
                        </button> */}

                    </div>
                    <div className="mr-3" data-toggle="modal" data-target="#openLoko">
                        <img src={TMSLoko} alt="first" />
                        <p>TMS LOKOMOTİF</p>


                    </div>
                    <div className="mr-3" data-toggle="modal" data-target="#openQR">
                        <img src={QR} alt="first" />
                        <p>TMSQR</p>

                    </div>

                </div>

                <div className="product-body">
                    <div className="mr-3" data-toggle="modal" data-target="#openIMS">
                        <img src={IMS} alt="first" />
                        <p>IMS</p>

                    </div>
                    <div className="mr-3" data-toggle="modal" data-target="#openTest">
                        <img src={Testbench} alt="testbenchbodyger" /> 
                        <p>TESTBENCH</p>


                    </div>
                    <div className="mr-3" data-toggle="modal" data-target="#openOnboard">
                        <img src={OnBoard} alt="first" />
                        <p>TMS ONBOARD</p>

                    </div>

                </div>

                <div className="modal fade" id="openLoko" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TMS LOKOMOTİF
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={TMSLoko} className="popup-img"
                                                alt={TMSLoko}></img>
                                        </div>
                                        <div>
                                            <img src={TMSLoko2} className="popup-img"
                                                alt={TMSLoko2}></img>
                                        </div>
                                    </Slider>
                                </div>
                                <p >
                                    <ul style={{ listStyleType: "disc" }}>
                                        <li>
                                            Innovative Wartung Mentalität
                                        </li>
                                        <li>
                                            Fernzugriff
                                        </li>
                                        <li>
                                            Anstehende Wartungsmeldungen
                                        </li>
                                        <li>

                                            Nachverfolgen von Wartungsparametern
                                        </li>
                                        <li>

                                            Offen für Entwicklung
                                        </li>
                                    </ul>
                                    <b>Zweck;</b>
                                    <ol>
                                        <li>
                                            Die monatlichen und 6-monatigen Wartungsparameter des Loko ERTMS der Serie DE22000 können über das Portal abgerufen und aktualisiert werden. </li>
                                        <li>
                                            Erinnerung an anstehende Wartungsarbeiten per Benachrichtigung
                                        </li>
                                    </ol>

                                    <b>
                                        der Inhalt;</b>
                                    <ul>
                                        <li>
                                            <b>
                                                Monatliche Wartung: </b>
                                            Auf die monatlichen Wartungsinformationen der Lokomotivserie 22000 kann im Detail zugegriffen werden.

                                        </li>
                                        <li>
                                            <b>

                                                Vergangene Wartungsinformationen: </b>

                                            Monatliche und halbjährliche Wartungsinformationen der Lokomotivserie 22000 können abgerufen werden.

                                        </li>
                                        <li>
                                            <b>

                                                6-monatliche Wartung: </b>Die 6-Monats-Wartungsinformationen der Lokomotivserie 22000 können im Detail abgerufen werden.
                                        </li>
                                        <li>
                                            <b>

                                                Management-Panel: </b>
                                            Monatliche und 6-monatige Informationen der Lokomotivserie 22000 können einfach eingegeben werden.
                                        </li>
                                        <li>
                                            <b>

                                                Anstehende Wartung: </b>
                                            Monatliche Wartungsinformationen der Lokomotivserie 22000
                                        </li>
                                    </ul>
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Nah dran
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="openPortal" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TMS PORTAL
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={TMSPortal} className="popup-img"
                                                alt={TMSPortal}></img>
                                        </div>
                                        <div>
                                            <img src={TMSPortal2} className="popup-img"
                                                alt={TMSPortal2}></img>
                                        </div>
                                    </Slider>
                                </div>
                                <p >
                                    <b>
                                        Der Inhalt;</b>
                                    <ul>
                                        <li>
                                            <b>
                                                Neuigkeiten und Ankündigungen: </b>
                                            Effektive Darstellung von internen Nachrichten und Ankündigungen,
                                        </li>
                                        <li>
                                            <b>Zugkarten: </b>

                                            Auf Zugkarten; Km, Expedition, Cerbox, Profilmaße, Wartungstermine, Wartungshinweise usw. Verfolgung aller Informationen über den Zug sowohl auf dem Kiosk-Bildschirm als auch im Internet,
                                        </li>
                                        <li>
                                            <b>
                                                Aufzeichnung der durchgeführten Wartungen: </b>
                                            Erfassung und nachträgliche Nachverfolgung der durchgeführten Arbeiten mit den den Zugkarten beizufügenden Bordwartungsformularen,
                                        </li>
                                        <li>
                                            <b>

                                                Wartung-Reparatur-Handbücher: </b>Wartungs-Reparatur-Handbücher in den Profilen und Anleitungen und Formularen, auf die jederzeit von Smart-Geräten zugegriffen werden kann,
                                        </li>
                                        <li>
                                            <b>
                                                Offen für Entwicklung: </b>
                                            Die entwickelbare offene Plattform ermöglicht die Integration unterschiedlicher Projekte.
                                        </li>
                                    </ul>
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Nah dran
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="openQR" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TMSQR ANWENDUNG
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={QR} className="popup-img"
                                                alt={QR}></img>
                                        </div>
                                        <div>
                                            <img src={QR} className="popup-img"
                                                alt="a"></img>
                                        </div>
                                    </Slider>
                                </div>
                                <p >
                                    Mit der TmsQR-Anwendung und QR-Codes;
                                    <ul style={{ listStyleType: "circle" }}>
                                        <li>
                                            Informationen zu Ausrüstungskalibrierungsdaten und verbleibenden Tagen,
                                        </li>
                                        <li>
                                            Kalibrierzertifikate von Geräten,
                                        </li>
                                        <li>
                                            Betreiberlisten von Baumaschinen,
                                        </li>
                                        <li>
                                            Nachverfolgung von Chemikalienbeständen und Verfallsdatum,
                                        </li>
                                        <li>
                                            Chemische Substanz MSDS (Material Safety Datasheet) Informationen

                                        </li>
                                    </ul>
                                    Und wir können viele andere Informationen anzeigen und aus der Ferne aktualisieren, ohne den QR-Code zu ändern.
                                    <p>
                                        <b>
                                            Die Annehmlichkeiten, die die TmsQR-Anwendung bietet;</b>
                                        <ul style={{ listStyleType: "square" }}>
                                            <li>
                                                Fernaktualisierung aller Geräteinformationen,
                                            </li>
                                            <li>
                                                Einfacher Zugriff auf Informationen über die Ausrüstung mit einer benutzerfreundlichen Oberfläche,
                                            </li>
                                            <li>
                                                Bei Änderung der Kalibriertermine entfallen die Etikettenwechselarbeiten am Gerät, </li>
                                            <li>
                                                Bestands- und Verfallsdatumsverfolgung von Chemikalien,
                                            </li>
                                        </ul>
                                    </p>
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Nah dran
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="openIMS" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    IMS - Intelligentes Zugverfolgungssystem
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={IMS} className="popup-img"
                                                alt={IMS}></img>
                                        </div>
                                        <div>
                                            <img src={IMS2} className="popup-img"
                                                alt={IMS2}></img>
                                        </div>
                                    </Slider>
                                </div>
                                <p >
                                    <ul style={{ listStyleType: "decimal" }}>
                                        <li>

                                            Beschleunigung in drei Achsen
                                        </li>
                                        <li>
                                            die Wärme
                                        </li>
                                        <li>

                                            Sofortige Standortinformationen
                                        </li>
                                    </ul>
                                    <b>
                                        der Zweck;</b>
                                    <ul style={{ listStyleType: "square" }}>
                                        <li>
                                            Um die Bewegungen von Hochgeschwindigkeitszügen sofort überwachen zu können,
                                        </li>
                                        <li>


                                            Sammeln von Straßenzustands- und Schütteldaten für den Straßen- und Fahrgastkomfort,
                                        </li>
                                    </ul>
                                    <p className="mb-2">

                                        An jedem Triebzug ist ein Ausrüstungssatz montiert. Diese Geräte sind; Es besteht aus einer Reihe von Sensorpaketen zum Empfangen von Daten und einem Telekommunikationsgerät zum Zurücksenden dieser Daten an den Server.</p>
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Nah dran
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="openTest" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TMS TESTBENCH
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                        <img src={Testbench} className="popup-img"
                                                alt={Testbench}></img>
                                        </div>

                                    </Slider>
                                </div>
                                <p >
                                    TMS TESTBENCH
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Nah dran
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="openOnboard" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TMS ONBOARD
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={OnBoard} className="popup-img"
                                                alt={OnBoard}></img>
                                        </div>
                                    </Slider>
                                </div>
                                <p >
                                    Yakında
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Nah dran
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default Products;