import  { Component } from 'react'

class NotFound extends Component {
    componentDidMount = () => {
        window.location.replace("/")
    }

    render() {
        return (
            null
        )
    }
}
export default NotFound