import React, { Component } from 'react'

export default class AboutUs extends Component {
    render() {
        return (
            <div className="about-us" id="about-us">
                <h1 className="mb-3">
                    BİZ KİMİZ
                </h1>
                <h4>
                    Misyon
                </h4>
                <p className="mb-3">
                    Demiryolu sektöründe yerli teknolojiler ile AR-GE kültürünü güçlendirmek.
                </p>
                <h4 >
                    Vizyon
                </h4>
                <p>
                    Türkiye'nin demiryolunda dışa bağımlılığını azaltarak yerli projeleri dünyaya pazarlayan bir şirket olmak.
                </p>
            </div>
        )
    }
}
