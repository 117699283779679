import React, { Component } from 'react'
import Slider from "react-slick";
import TMSPortal from '../../Images/portalekran.png'
import TMSPortal2 from '../../Images/TMSPortal2.png'
import TMSLoko from '../../Images/loko.png'
import TMSLoko2 from '../../Images/loko2.PNG'
import QR from '../../Images/qr.jpg'
import IMS from '../../Images/Ims.png'
import IMS2 from '../../Images/IMS2.jpg'
import OnBoard from '../../Images/onboard.PNG'
import Testbench from '../../Images/Testbench.jpeg'
const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
};

class Products extends Component {

    render() {
        return (
            <div id="products" >
                <h3 className="w-100 text-center mb-4">PROJELERİMİZ</h3>
                <div className="product-body">
                    <div className="mr-3" data-toggle="modal" data-target="#openPortal">
                        <img src={TMSPortal} alt="TMS Portal" title="TMS Portal" />
                        <p>TMS PORTAL</p>
                        {/* 
                        <button type="button" className="btn btn-primary px-2 py-1" >
                                Detaylar
                        </button> */}

                    </div>
                    <div className="mr-3" data-toggle="modal" data-target="#openLoko">
                        <img src={TMSLoko} alt="TMS Loko" title="TMS Loko" />
                        <p>TMS LOKOMOTİF</p>


                    </div>
                    <div className="mr-3" data-toggle="modal" data-target="#openQR">
                        <img src={QR} alt="TMS QR" title="TMS QR" />
                        <p>TMSQR</p>

                    </div>

                </div>
                <div className="product-body">
                    <div className="mr-3" data-toggle="modal" data-target="#openIMS">
                        <img src={IMS} alt="IMS" title="IMS" />
                        <p>IMS</p>

                    </div>
                    <div className="mr-3" data-toggle="modal" data-target="#openTest">
                        <img src={Testbench} alt="testbenchbodyTR" title="testbenchbodyTR" /> 
                        <p>TMS TESTBENCH YAYINDA</p>


                    </div>
                    <div className="mr-3" data-toggle="modal" data-target="#openOnboard">
                        <img src={OnBoard} alt="TMS Onboard" title="TMS Onboard" />
                        <p>TMS ONBOARD</p>

                    </div>

                </div>

                <div className="modal fade" id="openLoko" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TMS LOKOMOTİF
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={TMSLoko} className="popup-img"
                                                alt="TMS Arge Lokomotif" title="TMS Arge Lokomotif" />
                                        </div>
                                        <div>
                                            <img src={TMSLoko2} className="popup-img"
                                                alt="TMS Arge Lokomotif-1" title="TMS Arge Lokomotif-1"/>
                                        </div>
                                    </Slider>
                                </div>
                                <p>

                                    <ul style={{ listStyleType: "disc" }}>
                                        <li>
                                            Yenilikçi Bakım Anlayışı
                                        </li>
                                        <li>
                                            Uzaktan Erişim
                                        </li>
                                        <li>
                                            Yaklaşan Bakım Bildirimleri
                                        </li>
                                        <li>
                                            Geriye Dönük Bakım Parametreleri Takibi
                                        </li>
                                        <li>
                                            Geliştirmeye açık
                                        </li>
                                    </ul>
                                    <b>Amaç;</b>
                                    <ol>
                                        <li>
                                            DE22000 Serisi Loko ERTMS aylık ve 6 aylık bakım parametrelerinin portal üzerinden ulaşılabilir ve güncellenebilir olması.
                                        </li>
                                        <li>
                                            Yaklaşan bakımların bildirim yolu ile hatırlatılması.
                                        </li>
                                    </ol>

                                    <b>İçerik;</b>
                                    <ul>
                                        <li>
                                            <b>Aylık Bakımlar: </b>22000 Lokomotif Serilerinin Aylık Bakım Bilgilerine Detaylı olarak ulaşılabilir.

                                        </li>
                                        <li>
                                            <b>Geçmiş Bakım Bilgileri: </b>22000 Lokomotif Serilerinin Aylık ve 6 Aylık geçmişte yapılmış olan bakım bilgilerine ulaşılabilir.

                                        </li>
                                        <li>
                                            <b>6 Aylık Bakımlar: </b>22000 Lokomotif Serilerinin 6 Aylık Bakım Bilgilerine detaylı olarak ulaşılabilir.
                                        </li>
                                        <li>
                                            <b>Yönetim Paneli: </b>22000 Lokomotif Serilerinin Aylık ve 6 Aylık bilgilerinin kolayca girişleri yapılabilir.
                                        </li>
                                        <li>
                                            <b>Yaklaşan Bakımlar :</b>22000 Lokomotif Serilerinin Aylık Bakım Bilgileri
                                        </li>
                                    </ul>
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Kapat
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="openPortal" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TMS PORTAL
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={TMSPortal} className="popup-img"
                                                alt={TMSPortal}></img>
                                        </div>
                                        <div>
                                            <img src={TMSPortal2} className="popup-img"
                                                alt={TMSPortal2}></img>
                                        </div>
                                    </Slider>
                                </div>
                                <p >
                                  <b>İçerik;</b>  
                                    <ul>
                                        <li>
                                            <b>Haber ve Duyurular: </b>Şirket içi haber ve duyuruların etkin bir şekilde sergilenmesi,
                                        </li>
                                        <li>
                                            <b>Tren Kartları: </b>Tren kartlarında; Km, Sefer, Cerbox, Profil Ölçüleri, Bakım Tarihleri, Bakım Notları vb. trene ait tüm bilgilerin hem Kiosk ekranından hem de internet üzerinden takibi,
                                        </li>
                                        <li>
                                            <b>Yapılan Bakımların Kayıt Edilmesi: </b>Tren kartlarına eklenecek on-board bakım formları ile yapılan çalışmaların kayıt altına alınması ve geriye dönük takibi,
                                        </li>
                                        <li>
                                            <b>Bakım-Onarım Manuelleri: </b>Profillerde yer alan bakım-onarım manuelleri ile akıllı cihazlardan her an ulaşılabilen kılavuzlar ve formlar,
                                        </li>
                                        <li>
                                            <b>Geliştirilmeye Açık: </b>Geliştirilmeye açık platform farklı projelerin entegrasyonuna imkan sağlamaktadır.
                                        </li>
                                    </ul>
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Kapat
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="openQR" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TMSQR UYGULAMASI
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>

                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={QR} className="popup-img"
                                                alt="TMS ARGE QR" title="TMS ARGE QR" />
                                        </div>
                                    </Slider>
                                </div>
                                <p>
                                    Hayata geçen TmsQR uygulaması ve QR kodlar ile;
                                    <ul style={{ listStyleType: "circle" }}>
                                        <li>
                                            Ekipman kalibrasyon tarihleri ve kalan gün bilgisi,
                                        </li>
                                        <li>
                                            Ekipmanlara ait kalibrasyon sertifikaları,

                                        </li>
                                        <li>
                                            İş makinaları operatör listeleri ,

                                        </li>
                                        <li>
                                            Kimyasal madde stok ve son kullanma tarihi takibi,

                                        </li>
                                        <li>
                                            Kimyasal madde MSDS(Material Safety Datasheet) bilgilerini

                                        </li>
                                    </ul>
                                    Ve pek çok diğer bilgileri görüntüleyebiliyor ve bu bilgileri
                                    QR kodu değiştirmeden uzaktan güncelleyebiliyoruz.
                                    <p>
                                        <b>TmsQR uygulamasının getirdiği kolaylıklar;</b>
                                        <ul style={{ listStyleType: "square" }}>
                                            <li>
                                                Ekipmanlara ait tüm bilgilerinin uzaktan güncellenebilmesi,
                                            </li>
                                            <li>
                                                Kullanıcı dostu bir arayüzle ekipman hakkında bilgilere kolayca erişebilmek,
                                            </li>
                                            <li>
                                                Kalibrasyon tarihleri değiştiğinde cihazın üzerindeki etiket değiştirme işlerinin ortadan kalkması,
                                            </li>
                                            <li>
                                                Kimyasal maddelerin stok ve son kullanma tarihi takibi,
                                            </li>
                                        </ul>
                                    </p>
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Kapat
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="openIMS" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    IMS - Akıllı Tren İzleme Sistemi
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={IMS} className="popup-img"
                                                alt="TMS Arge IMS" title="TMS Arge IMS" />
                                        </div>
                                        <div>
                                            <img src={IMS2} className="popup-img"
                                                alt="TMS Arge IMS-1" title="TMS Arge IMS-1"  />
                                        </div>
                                    </Slider>
                                </div>
                                <p >
                                    <ul style={{ listStyleType: "decimal" }}>
                                        <li>
                                            Üç eksende ivme
                                        </li>
                                        <li>
                                            Sıcaklık
                                        </li>
                                        <li>
                                            Anlık Konum Bilgileri
                                        </li>
                                    </ul>
                                    <b>Amaç;</b>
                                    <ul style={{ listStyleType: "square" }}>
                                        <li>
                                            Hızlı tren hareketlerini anlık olarak izleyebilmek,
                                        </li>
                                        <li>
                                            Yol ve yolcu konforu için yol durumu hakkında ve sarsıntı verilerini toplamak,
                                        </li>
                                    </ul>
                                    <p className="mb-2">
                                        Her tren setine bir dizi donanım monte edilmiştir. Bu cihazlar; veri almak için bir dizi sensör paketi ve bu verileri sunucuya geri göndermek için bir telekomünikasyon cihazından oluşur.
                                    </p>
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Kapat
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="openTest" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TESTBENCH
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                        <img src={Testbench} className="popup-img"
                                                alt="TMS Arge TestBench" title="TMS Arge TestBench" />
                                        </div>

                                    </Slider>
                                </div>
                                <p >
                                    TMS TESTBENCH YAYINDA
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Kapat
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="openOnboard" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                            <div className="modal-header mx-auto modal-header-part">
                                <span className="modal-header-part-span">
                                    TMS ONBOARD
                                </span>

                                <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                                    ×
                                </button>
                            </div>
                            <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>

                                <div style={{ textAlign: "center" }} >
                                    <Slider {...settings}>
                                        <div>
                                            <img src={OnBoard} className="popup-img"
                                                alt="TMS Arge Onboard" title="TMS Arge Onboard" />
                                        </div>
                                    </Slider>
                                </div>
                                <p >
                                    Yayında
                                </p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">
                                    Kapat
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default Products;