import React, { Component } from 'react'

export default class AboutUs extends Component {
    render() {
        return (
            <div className="about-us" id="about-us">
                <h1 className="mb-3">
                    WHO ARE WE
                </h1>
                <h4>
                    Mission
                </h4>
                <p className="mb-3">
                    Strengthening the R&D culture through local technologies in the railway sector.
                </p>
                <h4 >
                    Vision
                </h4>
                <p>
                    To become a company commercializing local projects to the World by reducing Turkey's dependency on foreing railways. The railway sector.
                </p>
            </div>
        )
    }
}
