import React, { Component } from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import AboutUs from './Components/AboutUs'
import News from './Components/News'
import Products from './Components/Products'
import References from './Components/References'
import { Helmet } from "react-helmet";
import TMSArge from '../Images/arge.PNG'
export default class HomePage extends Component {

    componentDidMount = () => {
        document.documentElement.lang = "de";
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title data-react-helmet="true">
                        TMS F&E | TMS Zug Wartungsdienste Inc.
                    </title>
                    <meta http-equiv="content-language" content="de" />
                    <meta
                        name="description"
                        content="TMS F&E | Besuchen Sie als TMS F&E Team jetzt unsere Website, um unsere Projekte zu sehen und das TMS R&D Team kennenzulernen | TMS Zug Wartungsdienste Inc."
                        data-react-helmet="true"
                    />
                    <meta name="keywords" content="TMS F&E" data-react-helmet="true" />

                    <link rel="alternate" hreflang="tr" href="https://www.tmsarge.com/" data-react-helmet="true" />
                    <link rel="alternate" hreflang="en" href="https://www.tmsarge.com/eng" data-react-helmet="true" />
                    <link rel="alternate" hreflang="de" href="https://www.tmsarge.com/de" data-react-helmet="true" />
                    <link rel="alternate" hreflang="x-default" href="https://www.tmsarge.com/" data-react-helmet="true" />
                    <link rel="canonical" href="https://www.tmsarge.com/de" data-react-helmet="true" />

                </Helmet>
                <Navbar />

                <div className="carousel slide carousel-fade" data-ride="carousel" data-interval="5000" id="carousel-1">
                    <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active">
                            <img className="img-fluid w-100 d-block" src={TMSArge} alt="TMS Arge" title="TMS Arge" />
                            <div className="carousel-caption">
                                <h3 className="animated slideInDown">TMS F&E</h3>
                                <p className="animated slideInLeft">Innovation, Futuristisch, Kreativ und Letzte Technologie</p>
                                <a href="#about-us" className="btn btn-outline-light btn-lg animated slideInUp mt-4" type="button">
                                    Mehr
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="an">
                    <AboutUs />
                    <News />
                </div>

                <Products />

                <References />

                <Footer />
            </div>

        )
    }
}
