import React, { useRef } from 'react'

function Contact() {
    let Name = useRef()
    let Email = useRef()
    let Subject = useRef()
    let Message = useRef()

    const onClickSend = () => {

        if (Name.current.value === "" || Email.current.value === "" || Subject.current.value === "" || Message.current.value === "") {
            alert("Please Fill in the All Blanks in Contact Part")
        }
        else {

            window.Email.send({
                Host: "mail.tmsarge.com",
                Username: "mail@tmsarge.com",
                Password: "Mail@2020",
                To: [
                    "atahayurdakul@tmstechnic.com",
                    "ehuseyinbalin@tmstechnic.com"
                ],
                From: "mail@tmsarge.com",
                Subject: Subject.current.value,
                Body: "Gönderen Kişi: "+Name.current.value + " Gönderen Maili: " + Email.current.value + " Mesaj: " + Message.current.value
            }).then(() => {
                Name.current.value = ""
                Email.current.value = ""
                Subject.current.value = ""
                Message.current.value = ""
            })
        }
    }
    return (
        <div style={{ fontSize: "13px" }}>
            <div className="mt-3">

                <input type="text" ref={Name} className="p-1 contact-first-row" placeholder="Your Name" style={{ marginRight: "2%" }} />

                <input type="email" ref={Email} className="p-1 contact-first-row mb-0" placeholder="Your Email" />

            </div>

            <input type="text" ref={Subject} className="p-1 my-3 w-100" placeholder="Subject" />

            <textarea cols="25" ref={Message} rows="7" className="p-1 w-100" placeholder="Message" />

            <div className="text-right">
                <button className="btn button-send" onClick={onClickSend}>
                    SEND A MESSAGE
                </button>
            </div>

        </div>
    )
}
export default Contact;